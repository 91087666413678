const messages = {
  aboutUsMessage: "Conoce al equipo que revolucionará tus proyectos",
  aboutUsDescription1:
    "Somos una agencia digital enfocada a brindar herramientas para empresas que necesiten evolucionar en el desarrollo tecnológico y el mundo digital. Ofrecemos servicios desde proyecciones de marca hasta soluciones estratégicas orientadas a la web y comercio electrónico.",
  aboutUsDescription2:
    "Nuestra trayectoria de más de 10 años en el mercado dominicano e internacional representa nuestro compromiso y pasión por brindar servicios de calidad, orientados a los objetivos estratégicos que nuestros clientes anhelan cumplir.",
  ourTeam: "Nuestro Equipo",
  founder: "Fundador & Lider del Equipo de Desarrollo",
  coFounder: "Co-fundadora & Gerente Administrativa",
  frontEndDeveloper: "Desarrollador Front End",
  backEndDeveloper: "Desarrollador Back End Node.JS",
  directorOfArts: "Directora de Artes",
  fullStack: "Desarrollador Full Stack",
  scrumMaster: "Manejador de Proyecto",
  qualityAnalyst: "Analista de Calidad de Software",
  juniorDevelop: "Desarrolladora Junior",
  dataAnalyst: "Analista de Datos de Éxito (Power BI)",
  graphicDesigner: "Diseñador Gráfico Junior",
  graphicDesignerF: "Diseñadora Gráfica Junior",
  portfolioDownload: "Descarga nuestro portafolio",
  portfolio: "/portafolio.pdf",
  btnDownload: "Descargar",
  sysAdmin: "Administrador de Sistemas",
  adsDirector: "Directora de Publicidad"
};

export default messages;
