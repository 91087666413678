<template>
  <v-app-bar
    app
    :class="{ 'is-home-header': $routeMeta.slug == 'Home' }"
    flat
    :prominent="prominent"
  >
    <v-container class="py-0 d-flex align-self-center">
      <v-row justify="space-between">
        <v-col lg="3" xl="5" class="py-0 d-flex align-center">
          <router-link :to="`/${$i18n.locale}/home`">
            <v-img
              :lazy-src="logo.url"
              :max-width="logo.width"
              :class="{ 'is-home-logo': $routeMeta.slug == 'Home' }"
              :src="logo.url"
              style="cursor: pointer;"
              contain
            ></v-img>
          </router-link>
        </v-col>
        <v-col lg="9" xl="6" class="d-flex align-center py-0">
          <v-row class="py-3 d-lg-flex d-none" justify="end">
            <v-col align-self="stretch" lg="10" xl="10" order="1">
              <v-row>
                <v-tabs
                  class="remove-background"
                  v-model="activeTab"
                  :color="$routeMeta.meta.active.color"
                  center-active
                  fixed-tabs
                  :show-arrows="false"
                  :hide-slider="$routeMeta.slug === 'Contact'"
                  next-icon
                >
                  <div
                    v-for="(link, index) in getLinks"
                    :key="index"
                    class="d-flex mr-4 align-self-center"
                  >
                    <v-tab
                      class="text-capitalize font-weight-light black--text menu-option"
                      :id="link.id"
                      :active-class="'font-weight-bold'"
                      @click="
                        $router.push(`/${$i18n.locale}${link.overviewUrl}`)
                      "
                      :ripple="!link.useButton"
                    >
                      <v-btn
                        v-if="link.useButton"
                        elevation="0"
                        dark
                        small
                        :color="$routeMeta.meta.active.color"
                        class="mr-3 text-white"
                      >
                        {{ $t(link.title) }}
                      </v-btn>
                      <span v-else>
                        {{ $t(link.title) }}
                      </span>
                    </v-tab>
                  </div>
                </v-tabs>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="justify-end mt-n3 d-lg-none">
            <div class="text-center">
              <v-app-bar-nav-icon
                @click.stop="drawer = !drawer"
              ></v-app-bar-nav-icon>
              <v-navigation-drawer
                v-model="drawer"
                absolute
                bottom
                temporary
                class="menu"
              >
                <v-list>
                  <v-list-item-group v-model="activeTab">
                    <v-list-item
                      v-for="(link, index) in getLinks"
                      :key="index"
                      @click="
                        $router.push(`/${$i18n.locale}${link.overviewUrl}`)
                      "
                      :color="$routeMeta.meta.active.color"
                    >
                      <v-list-item-title class="py-5">{{
                        $t(link.title)
                      }}</v-list-item-title>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-navigation-drawer>
            </div>
          </v-row>
          <v-col cols="1">
            <v-row class="py-3">
              <v-select
                v-model="country"
                :items="getCountries"
                class="select_language"
              >
                <template slot="selection" slot-scope="{ item }">
                  <span class="flag_container">
                    <country-flag :country="item.flag"
                  /></span>
                </template>
                <template slot="item" slot-scope="{ item }">
                  <a href="#" @click="setLocale(item)">
                    <span class="flag_container_list">
                      <country-flag :country="item.flag"
                    /></span>
                  </a>
                </template>
              </v-select>
            </v-row>
          </v-col>
        </v-col>
      </v-row>
    </v-container>
  </v-app-bar>
</template>

<script>
import { mapGetters } from "vuex";
import ViewsMixin from "../mixins/ViewMixin";
import CountryFlag from "vue-country-flag";
// import CountryFlag from "vue-country-flag-next";

export default {
  name: "HeaderBar",
  mixins: [ViewsMixin],
  props: {
    prominent: Boolean
  },
  components: {
    CountryFlag
  },
  data: () => ({
    drawer: false,
    group: null,
    activeTab: 2,
    country: {
      flag: "do",
      locale: "es"
    }
  }),
  created() {
    this.setRouteIndex();
    const country = localStorage.getItem("country");
    if (country) {
      this.country = JSON.parse(country);
    }
  },
  methods: {
    setLocale(country) {
      localStorage.setItem("country", JSON.stringify(country));
      this.$router.replace({
        name: this.$route.name,
        params: { locale: country.locale }
      });
      location.reload();
    },
    setRouteIndex() {
      this.activeTab = this.getLinks.findIndex(link =>
        this.$route.fullPath.includes(link.url)
      );
    }
  },
  computed: {
    ...mapGetters(["getLinks", "getCountries"]),

    logo: function() {
      if (this.prominent === true && this.$vuetify.breakpoint.name !== "xs") {
        return {
          url: "/logo_color.svg",
          width: 180
        };
      } else {
        return {
          url: "/logoshort.svg",
          width: 40
        };
      }
    }
  },
  watch: {
    $route: "setRouteIndex",
    group() {
      this.drawer = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.theme--light.v-tabs .v-tab:hover::before {
  opacity: 0;
}
.v-tab:before {
  transition: none;
}
</style>
